// src/components/Footer.js
import React from 'react';
import '../styles/footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-left">
        <div className="footer-logo">
          <h1>Reem Properties</h1> {/* Replace with your logo */}
        </div>
      </div>
      <div className="footer-middle">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="/about">About</a></li>
          <li><a href="/portfolio/commercial">Commercial Properties</a></li>
          <li><a href="/portfolio/residential">Residential Properties</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
      <div className="footer-right">
        <h3>Address</h3>
        <address>
          1234 Greenpoint Ave<br />
          Brooklyn, NY, USA <br />
          <a href="mailto:info@reemproperties.com">info@reemproperties.com</a><br />
          <a href="tel:+7182536600">718-253-6600</a>
        </address>
      </div>
    </footer>
  );
};

export default Footer;
