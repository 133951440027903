// src/components/residential.js
import React from 'react';
import '../styles/properties.css';


const Residential = () => {
  return (
    <div className="residential-page">
      <h1>Residential Properties</h1>
      <p>Discover our selection of residential properties, perfect for your next home or investment.</p>
      <ul>
        <li>Property 1: Luxury Apartment - Upper East Side</li>
        <li>Property 2: Family Home - Brooklyn Heights</li>
        <li>Property 3: Townhouse - Staten Island</li>
      </ul>
    </div>
  );
};

export default Residential;
