// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';

const Header = () => {
  return (
    <header className="site-header">
      <div className="logo">
        <h1>Reem Properties</h1> {/* Replace with your logo */}
      </div>
      <nav>
        <ul>
          <li><Link to="/about">About</Link></li>
          <li className="dropdown">
            <span>PORTFOLIO</span>
            <ul className="dropdown-content">
              <li><Link to="/portfolio/commercial">Commercial Properties</Link></li>
              <li><Link to="/portfolio/residential">Residential Properties</Link></li>
            </ul>
          </li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
