// src/components/commercial.js
import React from 'react';
import '../styles/properties.css';


const Commercial = () => {
  return (
    <div className="commercial-page">
      <h1>Commercial Properties</h1>
      <p>Explore our exclusive range of commercial properties. We offer the best commercial spaces for your business.</p>
      <ul>
        <li>Property 1: Office Building - Manhattan</li>
        <li>Property 2: Retail Space - Brooklyn</li>
        <li>Property 3: Industrial Warehouse - Queens</li>
      </ul>
    </div>
  );
};

export default Commercial;
