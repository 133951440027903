// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import About from './components/about';
import Contact from './components/contact';
import Residential from './components/residential';
import Commercial from './components/commercial';
import './App.css';

const App = () => {
  return (
    <Router>
      <div id="root">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<About />} /> {/* Default to About */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio/residential" element={<Residential />} />
            <Route path="/portfolio/commercial" element={<Commercial />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
