// src/components/about.js
import React from 'react';

const About = () => {
  return (
    <div className="about-page">
      {/* Welcome message */}
      <div>
        <h1>Welcome to Reem Properties!</h1>
        <p>Your dream home is just a click away!</p>
      </div>

      {/* Existing mission content */}
      <h1>OUR MISSION</h1>
      <p>
        At Reem Properties, our mission is to provide exceptional real estate services that empower our clients to achieve their personal and business goals. 
        We strive to offer a comprehensive range of residential and commercial properties, tailored to meet the unique needs of each client. 
        We believe in fostering strong relationships with our customers, built on trust, integrity, and transparency.
      </p>
      <p>
        We are committed to delivering high-quality properties that provide value and opportunity, and to maintaining the highest standards of professionalism. 
        Our goal is to exceed expectations in every aspect of real estate, from consultation to closing, and to ensure that our clients feel confident and supported throughout the entire process.
      </p>
      <p>
        With a focus on innovation, sustainability, and long-term success, Reem Properties aims to make a positive impact on the communities we serve, while continuing to grow and evolve as a leading real estate provider.
      </p>
    </div>
  );
};

export default About;
