// src/components/contact.js
import React, { useRef } from 'react';
import '../styles/contact.css'; // Import the CSS file for styling

const Contact = () => {
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data
    const formData = {
      firstName: form.current.user_firstname.value,
      lastName: form.current.user_lastname.value,
      email: form.current.user_email.value,
      phone: form.current.user_phone.value,
      message: form.current.message.value,
    };

    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Message sent successfully!');
        form.current.reset();
      } else {
        const errorText = await response.text();
        console.error('Error:', errorText);
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message. Please try again later.');
    }
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p className="description">
        Please complete the form below or contact us directly. We look forward to hearing from you.
      </p>

      <div className="contact-content">
        <div className="contact-form">
          <h2>Send Us A Message</h2>
          <form ref={form} onSubmit={handleSubmit}>
            <div className="name-fields">
              <input type="text" name="user_firstname" placeholder="First Name" required />
              <input type="text" name="user_lastname" placeholder="Last Name" required />
            </div>
            <input type="email" name="user_email" placeholder="Email" required />
            <input type="tel" name="user_phone" placeholder="Phone" />
            <textarea name="message" placeholder="Message" required></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
